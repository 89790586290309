import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Tasks } from './tasks';

@Injectable({
  providedIn: 'root'
})
export class TaskdbService {

  private dbPath = '/tasks';
 
  tasksRef: AngularFireList<Tasks> = null;

  constructor(private db: AngularFireDatabase) {
    this.tasksRef = db.list(this.dbPath);
   }

  createTask(tasks: Tasks): void {
    this.tasksRef.push(tasks);
  }
 
  updateTask(key: string, value: any): Promise<void> {
    return this.tasksRef.update(key, value);
  }
 
  deleteTask(key: string): Promise<void> {
    return this.tasksRef.remove(key);
  }
 
  getTasksList(): AngularFireList<Tasks> {
    return this.tasksRef;
  }
 
  deleteAll(): Promise<void> {
    return this.tasksRef.remove();
  }

}
