import { Component, OnInit } from '@angular/core';
import { TaskdbService } from '../taskdb.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  tasks:any;

  constructor(private tasksdbService: TaskdbService) { }

  ngOnInit(): void {
    this.getTasksList();
  }

  getTasksList() {
    this.tasksdbService.getTasksList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.key, ...c.payload.val() })
        )
      )
    ).subscribe(tasks => {
      this.tasks = tasks;
    });
  }
 
  deleteTasks() {
    this.tasksdbService.deleteAll().catch(err => console.log(err));
  }

}
