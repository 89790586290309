<br>


<div class="ui middle aligned center aligned grid">
  
    <div class="column">
            <h1 class="ui teal image header">
             
        <br/>
        <div class="content">
      Awesome Analytics
        </div>
      </h1>
        <br><br>

      <form class="ui large form">
        <div class="ui stacked segment">
            <h1 class="ui teal image header">
              <img src="assets/images/logo.png" class="image">
                <br/>
                <div class="content">
                    Task Management
                  </div>
              </h1>
              <br/> <br/> 
          
          <div class="field">
            <div class="ui left icon input">
              <i class="user icon"></i>
              <input type="text" name="email" placeholder="E-mail address">
            </div>
          </div>
          <div class="field">
            <div class="ui left icon input">
              <i class="lock icon"></i>
              <input type="password" name="password" placeholder="Password">
            </div>
          </div>
          <button class="ui fluid large teal submit button" routerLink="/home">Login</button>
        </div>
  
        <div class="ui error message"></div>
  
      </form>
  
      <div class="ui message">
        New to us? <a href="#">Sign Up</a>
      </div>
    </div>
  </div>
  