// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyA9GL6BY4u6dRXnr5xQfdwbZnks4hu7NaM",
    authDomain: "task-management-ea4ef.firebaseapp.com",
    databaseURL: "https://task-management-ea4ef.firebaseio.com",
    projectId: "task-management-ea4ef",
    storageBucket: "task-management-ea4ef.appspot.com",
    messagingSenderId: "489471510729",
    appId: "1:489471510729:web:ceca447d4ba112c28ae518"
  },
  production: false
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
