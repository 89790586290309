
<br>
      <table class="ui celled table">
        <thead>
          <th>Task</th>
          <th>Owner</th>
          <th>Description</th>
          <th>State</th>
          <th>Due On</th>
          <th> Operation</th>
        </thead>
        

          <tbody *ngFor="let t of tasks">
              <td>{{t.task}}</td>
              <td>{{t.owner}}</td>
              <td>{{t.desc}}</td>
              <td>{{t.status}}</td>
              <td>{{t.date}}</td>
              <td><span (click)='deleteTask()'>Delete</span></td>
           
            </tbody>
          
       
          <!-- <span class="button is-small btn-primary" *ngIf='tasks.active' (click)='updateActive(false)'>Inactive</span>
   
          <span class="button is-small btn-primary" *ngIf='!tasks.active' (click)='updateActive(true)'>Active</span> -->
         
          <!-- <span class="button is-small btn-danger" (click)='deleteTask()'>Delete</span>
          -->
         
        
      </table>
   
