<div class="ui fixed menu">
    <div class="ui text container">
      <div class="header item">
        <img class="logo" src="assets/images/logo.png">
         &nbsp;Task Management
      </div>

      <a href="" class="ui right floated dropdown item">
        Logout 
      </a>
    </div>
  </div>

  <div class="ui text container">

    <br/>
    <br/>
    <h1> All Tasks </h1>

    <nav>
      <button routerLink="/addTask" class="ui teal button" role="button" routerLinkActive="active">Add</button>
    </nav>

  <app-task-details [tasks]='tasks'></app-task-details>
  
<div style="margin-top:20px;">
  <button type="button" class="ui red button" (click)='deleteTasks()'>Delete All Tasks</button>
</div>

  </div>


   