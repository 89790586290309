import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { CreateTaskComponent } from './create-task/create-task.component';
import {TaskDetailsComponent} from './task-details/task-details.component';

const routes: Routes = [
  {path:'',component : LoginComponent},
  {path:'home',component : HomeComponent},
  {path:'addTask',component : CreateTaskComponent},
  {path:'view',component : TaskDetailsComponent},
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
