import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Tasks } from '../tasks';
import { TaskdbService } from '../taskdb.service';

@Component({
  selector: 'app-create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.css']
})
export class CreateTaskComponent implements OnInit {

  tasked: Tasks = new Tasks();
  submitted = false;

  constructor(private tasksdbService: TaskdbService) { }

  ngOnInit(): void {
  }

  newTask(): void {
    this.submitted = false;
    this.tasked = new Tasks();
  }
 
  save() {
    this.tasksdbService.createTask(this.tasked);
    this.tasked = new Tasks();
  }
 
  onSubmit() {
    this.submitted = true;
    this.save();
  }

}
