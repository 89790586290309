<div class="ui fixed menu">
    <div class="ui text container">
      <div class="header item">
        <img class="logo" src="assets/images/logo.png">
         &nbsp;Task Management
      </div>

      <a href="" class="ui right floated dropdown item">
        Logout 
      </a>
    </div>
  </div>

  <div class="ui text container">

    <br/>
    <br/>
    <h1> Create Task </h1>

    
    <nav>
        <button routerLink="/home" class="ui teal button" role="button" routerLinkActive="active">View All Tasks</button>

      </nav>

      <br>


      <div [hidden]="submitted"  class="ui form">
        <form (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="name">Task</label>
            <input type="text" class="form-control" id="name" required [(ngModel)]="tasked.task" name="name">
          </div>

          <div class="form-group">
            <label for="name">Description</label>
            <input type="text" class="form-control" id="desc" required [(ngModel)]="tasked.desc" name="desc">
          </div>

          <div class="form-group">
            <label for="name">Status</label>
            <input type="text" class="form-control" id="status" required [(ngModel)]="tasked.status" name="status">
          </div>

          <div class="form-group">
            <label for="name">Owner</label>
            <input type="text" class="form-control" id="owner" required [(ngModel)]="tasked.owner" name="status">
          </div>
          
       
          <div class="form-group">
            <label for="name">Due Date</label>
            <input type="date" class="form-control" id="date" required [(ngModel)]="tasked.date" name="date">
          </div>
          
       <br>

          <button type="submit" class="ui teal button">Add Task</button>
        </form>
      </div>
       
      <div [hidden]="!submitted">
        <h4>Task Added</h4>
        <button class="ui teal button" (click)="newTask()">Add More</button>
      </div>



  </div>