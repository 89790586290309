import { Component, OnInit, Input } from '@angular/core';
import { Tasks } from '../tasks';
import { TaskdbService } from '../taskdb.service';

@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.css']
})
export class TaskDetailsComponent implements OnInit {

  @Input() tasks: Tasks;

  constructor(private tasksdbService: TaskdbService) { }

  ngOnInit(): void {
    console.log(this.tasks);
  }


  updateActive(isActive: boolean) {
    this.tasksdbService
      .updateTask(this.tasks.key, { active: isActive })
      .catch(err => console.log(err));
  }
 
  deleteTask() {
    this.tasksdbService
      .deleteTask(this.tasks.key)
      .catch(err => console.log(err));
  }

}
